import React from "react"
import Layout from "../components/Layout"
import Footer from "../components/Footer"
import SEO from "../components/SEO"
import DetailSection from "../components/DetailSection"
import NavBar from "../components/NavBar"
import coffee from "../images/coffee.jpg"
import vending from "../images/vending.jpg"
import micromart from "../images/micromart.jpg"

export default function Services(props) {
    return (
        <Layout>
            <SEO
                title="Services"
            />
            <NavBar />
            <DetailSection
                img={coffee}
                order="order-first"
                intro="Introducing"
                title="Open Vending"
                detail="The ultimate vending service."
                body="No machines? No problem. Open Vending is a headache-free full service vending solution. All your favorite snacks and drinks, delivered and setup at your location."
                feature="Customizable"
                featureDetail="You can customize open vending orders to have your favorite snacks and drinks. Even fresh food options. Changes are seamless and we don't need to pull anything out of a machine."
                feature2="Simple"
                featureDetail2="No more wiping down machines or touching a germ ridden keypad. With open vending you can grab and go. Stop wasting time waiting for your snacks to fall from the machine."
                feature3="Convenient"
                featureDetail3="With flexible and recurring scheduling, you can easily pick when you want your goods. Want to get snacks and drinks every Monday at 8am? No problem!"
                question="Ready for a headache-free, convenient vending experience?"
                hideContact="hidden"
                showProductSlider="hidden"
            />
            <DetailSection
                img={vending}
                order="order-first"
                intro="Traditional"
                title="Vending Machines"
                detail="The classic vending service."
                body="Need something a little more traditional? We also supply vending machines!"
                feature="Wide Selection"
                featureDetail="We offer 6 selection, 12 selection, and 24 selection machines. Including drink, snack, and combo machines. All accepting cash or credit card."
                feature2="Well Maintained"
                featureDetail2="Are machines are clean, efficient, and well maintained. Have an issue? We provide maintenance on an as needed basis and will promptly solve your problem."
                question="Ready to enjoy vending machines without the hassle?"
                hideContact="hidden"
                showProductSlider="hidden"
            />
            <DetailSection
                img={micromart}
                order="order-first"
                intro="Smart"
                title="Micro Marts"
                detail="The autonomous vending solution."
                body="Don't want a vending machine or open vending? Enter Micro Marts... "
                feature="Self Checkout"
                featureDetail="No need to wait at the vending machine, users can checkout multiple items at once. Snacks and drinks are extremely accessible and users can
                save time by not needing to leave the building."
                feature2="Full Availability"
                featureDetail2="Have employees that work later shifts? Or just want to grab a snack or drink for the drive home? Micro Marts can be open 24/7 to allow access to all your
                favorite snacks and drinks around the clock."
                feature3="Energy Efficiency"
                featureDetail3="
                Compared to vending machines, micro marts use much less energy. Without sacrificing any convenience  at all. 
                So if you are looking to reduce your energy footprint, micro marts are the way to go!"
                question="Ready to see vending meet smart technology?"
                hideContact="hidden"
                showProductSlider="hidden"
            />
            <Footer />

        </Layout>
    )
}
